// change the .png to .webp in the images array

const images = [{
    id:1,
    src: 'img4.webp',
    alt:'calm river surrounded by leafy forest'
},
{
    id: 2,
    src: 'img5.webp', 
    alt: 'water treatment info event'
},
{
    id: 3,
    src: 'img6.webp',
    alt: 'water treatment info board with blue mascot'
},
{
    id: 4,
    src: 'img7.webp',
    alt: 'water treatment info station. Board, table and mascot suit'
},
]

export default images