import React from 'react'
import './Schedule.css'

import water from '../assets/aa_water.png'

// component for the schedule page
import ScheduleComponent from '../components/ScheduleComponent'

const Schedule = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>Schedule</h1>
        </div>

        <ScheduleComponent />
        
      </div>

    </div>
  )
}

export default Schedule