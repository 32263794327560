const images = [{
    id:1,
    src: 'img10.webp',
    alt:'large round treatment tank on concrete'
},
{
    id: 2,
    src: 'img11.webp', 
    alt: 'tall wall of pumps and machines'
},
{
    id: 3,
    src: 'img2.webp',
    alt: 'interior of treatment plant, large metal pipes'
},
]

export default images