import React from 'react'
import './Main.css'
import './CommunityEngagement.css'


// image
import water from '../assets/aa_water.png'

// components
import Carousel from '../components/Carousel'

// data
import images from '../data/ce_data.js'

const Implementation = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>Implementation</h1>

        </div>

        <div className = 'p_wrapper_main'>
          <p className = 'p_main p_ce'> 
          The focus of the project implementation task is to describe the plan to rehabilitate the facility's original construction, which occurred in the 1930s and 1940s (Plant 1). The project implementation task will distill the findings from the Alternatives Evaluation into a Preliminary Basis of Design Report. This report will become a foundational document for the future final design and construction project to rehabilitate Plant 1.
          </p>
          <br></br>
          <p className = 'p_main p_ce'> 
          Work on project implementation will begin after the preferred alternative is identified, and results from pilot testing are available. The Preliminary Basis of Design Report will also include project cost estimating and cash flow analysis for the purpose of annual fiscal year budgeting, along with development of a master schedule of existing and proposed projects. Funding sources in the form of low interest loans and grants from state and federal agencies will also be explored, and documents and applications for selected funding opportunities will be prepared.
          </p>
        </div>
      </div>

    <div className="right_col_main">
      <Carousel images={images} />
    </div>
    
  </div>
  )
}

export default Implementation