import React from 'react'
import './Main.css'
import './StrategicPlanning.css'

// assets
import water from '../assets/aa_water.png'
import hero from '../assets/aaw_strategic_plan.webp'


const StrategicPlanning = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>strategic planning</h1>
        </div>

        <div className = 'p_wrapper_main'>
          <p className = 'p_main p_sp opening_paragraph'> 
          On Feb. 20, 2024, Ann Arbor City Council adopted the Strategic Plan for Ann Arbor Water. Prepared as part of the Water Treatment Plant Facility Plan, the report outlines the vision for the future of Ann Arbor's drinking water treatment unit through the lens of a 50-year planning horizon.
          <br></br>
          You can access the Strategic Plan report <a className='strat_plan_link' target='_blank' rel="noreferrer" href = '../pdfs/Ann Arbor Water Strategic Plan_adopted.pdf'>here</a>.
          </p>
          <br></br>
          <p className = 'p_main p_sp'> 
          The purpose of the Strategic Planning Roadmap for the Water Treatment Plant (WTP) is to lay out a vision and Roadmap for Ann Arbor's water supply with input from the community and WTP experts. The Roadmap document, including guiding principles, will guide decision-making: do the decisions align with the goals set forth for Ann Arbor water? Strategic planning will guide all aspects of the facility plan. All decisions - from technical to aspirational, transactional to transformative - will serve the goals and plans set forth in the Roadmap.
          </p>
          <br></br>
          <p className = 'p_main p_sp'>
          The Executive Leadership Team includes City leaders with authority for decision making and is supported by a core team of WTP staff and consultants. These two teams ensure a successful project. Strategic Planning included multiple workshops with the City of Ann Arbor Water Treatment Plant core team, culminating in the Roadmap. In addition to members from the City of Ann Arbor, the strategic planning team also included representatives from the Huron River Watershed Council, Ann Arbor Equitable Engagement Steering Committee, Ann Arbor Township, Scio Township, University of Michigan and Ann Arbor Public Schools.
          </p>
        </div>
      </div>

      <div className="right_col_sp">
        <img src={hero} alt='strategic plan cover'/>
      </div>
    </div>
  )
}

export default StrategicPlanning