import React from 'react'
import { Link } from 'react-router-dom'
import './Main.css';
import './Faqs.css';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

// https://www.npmjs.com/package/react-collapsible


// images
import water from '../assets/aa_water.png'

const Faqs = () => {
  const settings = {
    tabIndex: 0,
    contentHiddenWhenClosed: false,
  }
  return (
    <div className = 'wrapper_faq'>
    <div className = 'left_col_faq'>
      
      <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
      <div className = 'title_wrapper_main'>

        <h1 className = 'title_main'>FAQs</h1>
      </div>

      <div className = 'p_wrapper_main'>
        <div className = 'p_main faq_collap_wrap'>

          {/* Contact */}
          <Collapsible 
          {...settings}
          trigger={["Contact", <BsChevronDown />]} 
          className= 'collapsible_always collapsible_1_closed' openedClassName='collapsible_always collapsible_1_open'
          contentInnerClassName='collapsible_1_inner'>

            <ul className='question_outer'>
              <li className='question_text'>Where do I find more information about this project?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>You can find all the information relating to the project on the {<Link to='/'>Official Project Website</Link>}. The website includes important project updates, press releases, planned events and more. Go check it out!</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>Where will I see updates or new information?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>You will find updates through the City of Ann Arbor newsletter Quality Water Matters Archive <a target = "_blank" rel="noreferrer" href = "https://www.a2gov.org/departments/water-treatment/Pages/Quality-Water-Matters-Archive.aspx">(a2gov.org)</a>, the <a target = "_blank"  rel="noreferrer" href = "https://www.a2gov.org/departments/water-treatment/about/Pages/default.aspx">Water Treatment Plant website</a>, the {<Link to='/'>Official Project Website</Link>}, and in local media.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>How can I get more involved with this project?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>We will be conducting focus groups, attending public events, and hosting a town hall-style meeting. For more information about how to attend these events, or for general questions, please see the {<Link to='/community-engagement'>Community Engagement</Link>} tab of the project website.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>How does The City plan on engaging residents on this project?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>The City will be engaging residents through focus groups, town hall meetings, videos, a survey, and by going out to events in the community. To find out where you can connect, please see the community engagement section of the {<Link to='/community-engagement'>Project Website</Link>}</li>
                </ul>
            </ul>

          </Collapsible>
          <hr></hr>
          {/* Project Background */}
          <Collapsible 
          {...settings}
          trigger={["Project Background", <BsChevronDown /> ]}
          className= 'collapsible_always collapsible_1_closed' openedClassName='collapsible_always collapsible_1_open'
          contentInnerClassName='collapsible_1_inner'>

            <ul className='question_outer'>
              <li className='question_text'>Why is the Water Treatment Plant being rehabilitated?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>Portions of the City's water treatment plant that were built in 1938 and 1949 are still in service. These elements require rehabilitation and do not meet current standards for treatment. This project will replace those original elements and position the City to provide reliable and high-quality water into the future.</li>
                  <li className='answer_text'>The City commissioned a study in 2015 to assess the options. The study recommended - and the City approved - investment in our local plant as opposed to connecting to a neighboring regional water supply that has the capacity to serve the City's 135,000 customers. The project includes strategic planning, an alternatives analysis, a small pilot plant used to evaluate new technology, and community engagement throughout.</li>
                </ul>
            </ul>

            
            <ul className='question_outer'>
              <li className='question_text'>Will this project improve water quality?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>Yes, this project will incorporate strategies to address both current and potential contaminants in the City's watershed.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>Does this plant rehabilitation relate to addressing the PFAS issue?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>In 2018 and 2019, the City installed new granular activated carbon (GAC) to address the PFAS issue. Since the GAC's installation, PFAS has not been detected in the City's drinking water. This project will assess additional recommendations to bolster the City's treatment of PFAS in preparation for future regulations that may be more restrictive than those in place today.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>What about 1,4-Dioxane?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>The City monitors the migration of 1,4-dioxane plume toward the wells and the Huron River at Barton Pond, the location of the WTP's river intake. Additional monitoring wells are being installed in the near future (2022-2023). For additional information please see <a target = "_blank" rel="noreferrer" href ="https://www.youtube.com/watch?app=desktop&v=0DawqVmA9AI">Gelman Plume Update: Sentinel Monitoring Well Installations - YouTube</a>. This project will assess treatment alternatives for 1,4 dioxane should they be needed at some point in the future.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>Is this our neighborhood's drinking water?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>If you live in the City of Ann Arbor or in certain parts of Scio and Ann Arbor Township, yes! The City provides drinking water to 135,000 people, with 85% coming from the Huron River and 15% coming from multiple wells located south of Ann Arbor. Please see the City's <a href = 'https://www.a2gov.org/departments/water-treatment/Pages/Water-Supply-and-Treatment.aspx'>Water Supply, Treatment, and Distribution</a> page for more information.</li>
                </ul>
            </ul>


          </Collapsible>
          <hr></hr>
          {/* Construction */}
          <Collapsible 
          {...settings}
          trigger={["Construction", <BsChevronDown />]} 
          className= 'collapsible_always collapsible_1_closed' openedClassName='collapsible_always collapsible_1_open'
          contentInnerClassName='collapsible_1_inner'>

            <ul className='question_outer'>
              <li className='question_text'>When will construction begin?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>The construction for the rehabilitation of the water treatment plant is anticipated to begin in 2026 or later. The construction schedule will be determined as a part of this project.</li>
                  <li className='answer_text'>Construction of the Pilot Plan will begin in February 2023 and continue through the spring. The pilot plant will operate for about one year.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>How can I get more information about construction as the start date draws nearer?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>Keep your eye on the project website for updates and you can subscribe to the City's monthly water quality newsletter where updates will be shared. Here is the link to subscribe - <a href = 'https://service.govdelivery.com/accounts/MIANNA/subscriber/new?topic_id=MIANNA_121'>Subscribe for Ann Arbor Water Quality-related Updates</a>.</li>
                </ul>
            </ul>

          </Collapsible>
          <hr></hr>
          {/* Costs */}
          <Collapsible 
          {...settings}
          trigger={["Costs", <BsChevronDown />]} 
          className= 'collapsible_always collapsible_1_closed' openedClassName='collapsible_always collapsible_1_open'
          contentInnerClassName='collapsible_1_inner'>

            <ul className='question_outer'>
              <li className='question_text'>How much will this project cost?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>The City is anticipating the project cost to be in excess of $100M for the Plant 1 replacement, but the WTP Facility Plan project is needed to define the project scope in greater detail. The project scope includes updating the cost estimate based on new requirements and information.</li>
                </ul>
            </ul>
            
            <ul className='question_outer'>
              <li className='question_text'>Will my rates go up?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>Rate increases to support the project cost are already in place. This study will update the project construction cost estimates.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>What considerations are included in factors contributing to costs?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>The cost considerations include the replacement of aging infrastructure, technologies to meet new regulatory requirements (I.e. softening improvements), new technologies to address raw water quality contaminants such as PFAS, Cryptosporidium, hexavalent chromium, 1,4-Dioxane, as well as resiliency and reliability.</li>
                </ul>
            </ul>

          </Collapsible>
          <hr></hr>
          {/* Lasting Effects */}
          <Collapsible 
          {...settings}
          trigger={["Lasting Effects", <BsChevronDown />]} 
          className= 'collapsible_always collapsible_1_closed' openedClassName='collapsible_always collapsible_1_open'
          contentInnerClassName='collapsible_1_inner'>

            <ul className='question_outer'>
              <li className='question_text'>How will this affect my drinking water? Taste? Smell?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>We expect little change to the taste and smell of our award-winning water. One of the advantages of rehabilitating our local water treatment plant is that continuing to use the same source water - the Huron River - will have the least impact on the taste or smell of your drinking water.</li>
                </ul>
            </ul>

            <ul className='question_outer'>
              <li className='question_text'>What will the environmental impacts of this project be?</li>
                <ul className='answer_outer'>
                  <li className='answer_text'>We're planning for infrastructure that will be resilient in the face of climate change and industrial pollutants. The project's goals, and the WTP's goals, will align with A2 Zero's carbon neutrality goals, but the specifics of that will be decided as a part of this project.</li>
                </ul>
            </ul>

          </Collapsible>

        </div>

        <br></br>

      </div>
    </div>

  </div>
  )
}

export default Faqs