const images = [{
    id:1,
    src: 'img9.webp',
    alt:'clear calm river surrounded by autumn trees'
},
{
    id: 2,
    src: 'img1.webp', 
    alt: 'water treatment building, brick and concrete'
},
{
    id: 3,
    src: 'img4.webp',
    alt: 'calm river surrounded by leafy forest'
},
]

export default images