import React from 'react'
import './Main.css'
import './Documents.css'

import water from '../assets/aa_water.png'

const Documents = () => {
  return (
    <div className = 'wrapper_main'>
    <div>

    
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
          <div className = 'title_wrapper_main'>

            <h1 className = 'title_main title_documents'>Documents</h1>
            <h3 className = 'subtitle__main'>Documents will be posted as they are finalized during the project</h3>
          </div>

        <div className = 'p_wrapper_main'>
          <div className = 'p_main list_item_documents'>
            <h4 className="list_heading_documents">Project Related Articles</h4>
              <ul className = 'document_page_ul'>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/news/pages/article.aspx?i=910'>Project Update No. 1 from August 31, 2022</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/news/pages/article.aspx?i=918'>Project Update No. 2 from September 30, 2022</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/news/pages/article.aspx?i=926'>Project Update No. 3 from November 7, 2022</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water/pages/article.aspx?i=29'>Project Update No. 4 from January 6, 2023</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water/pages/article.aspx?i=31'>Project Update No. 5 from January 27, 2023</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water-treatment/Documents/2022%20Water%20Quality%20Report%20Final%20Draft.pdf'>2022 Water Quality Report</a> (Page 3 contains the project update)</li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water/pages/article.aspx?i=43'>Project Update No. 6 from February 27, 2023</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water/pages/article.aspx?i=49'>Project Update No. 7 from March 27, 2023</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water/pages/article.aspx?i=59'>Project Update No. 8 from May 1, 2023</a></li>
                <li><a rel="noreferrer" href = 'https://www.wemu.org/wemu-news/2022-09-07/city-of-ann-arbor-unveils-its-new-plan-for-clean-drinking-water'>Radio interview with WEMU from Sept. 7, 2022</a></li>
                <li><a target='_blank' rel="noreferrer" href = 'https://www.secondwavemedia.com/concentrate/devnews/watertreatmentplant0650.aspx'>News article in Concentrated from Sept. 14, 2022</a></li>
                <li><p>Survey No. 2 and Quiz (Spring 2023) <i>*this survey is now closed</i></p></li>
                <li><a target='_blank' rel="noreferrer" href = 'https://www.a2gov.org/departments/water-treatment/Documents/quality_water_matters_newsletter_october_2022.pdf'>Quality Water Matters - October Issue</a></li>
                <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water-treatment/Pages/Quality-Water-Matters-Archive.aspx'>Quality Water Matters - Archived Issues</a></li>
                <li><a rel="noreferrer" href = 'https://www.mlive.com/news/ann-arbor/2023/02/heres-why-ann-arbor-is-building-a-temporary-water-treatment-plant.html'>News Article on Mlive from February 1, 2023</a></li>
                <li><a rel="noreferrer" href = 'https://www.mlive.com/news/ann-arbor/2023/02/heres-why-ann-arbor-is-building-a-temporary-water-treatment-plant.html'>Here's why Ann Arbor is building a temporary water treatment plant - mlive.com</a></li>
                <li><a rel="noreferrer" href = 'https://www.wemu.org/show/issues-of-the-environment/2023-05-24/issues-of-the-environment-10-year-100-million-ann-arbor-water-treatment-plant-project-getting-underway'>Radio interview with WEMU from May 24, 2023</a></li>



              </ul>
            <h4 className="list_heading_documents">Technical Memorandums and Reports</h4>
            <ul className = 'document_page_ul'>
              <li><a target='_blank' rel="noreferrer" href = '/pdfs/Ann Arbor Water Strategic Plan_adopted.pdf'>Ann Arbor Water Strategic Plan (Feb. 2024, Adopted by Council)</a></li>
              <li><a target='_blank' rel="noreferrer" href = '/pdfs/Ann Arbor Water_Strategic Plan One-page Summary.pdf'>One-page Summary Ann Arbor Water Strategic Plan (Feb. 2024)</a></li>
              <li><a rel="noreferrer" href = 'https://www.a2gov.org/departments/water-treatment/Documents/2022%20Water%20Quality%20Report%20Final%20Draft.pdf'>2022 Water Quality Report</a></li>
              <li><a target='_blank' rel="noreferrer" href = '/pdfs/Sept13CouncilReport.pdf'>Sept. 13, 2021 Council Report</a></li>
            </ul>
            <h4 className="list_heading_documents">Presentations</h4>
              <ul className = 'document_page_ul'>
                <li><a target='_blank' rel="noreferrer" href = '/pdfs/Sept13CouncilPresentation.pdf'>Sept. 13, 2021 Council Presentation</a></li>
              </ul>
            <h4 className="list_heading_documents">Community Engagement</h4>
            <h4 className="list_heading_documents">Videos</h4>
              <ul className = 'document_page_ul'>
                <li><a rel="noreferrer" href = 'https://youtu.be/XMxWsBJz-Zk'>Ann Arbor Water Treatment Plant Facilities Plan Overview - Youtube</a></li>
                <li><a rel="noreferrer" href = 'https://youtu.be/Pq5wKOxAgHE'>Ann Arbor Drinking Water Survey</a></li>
              </ul>
          </div>
        </div>
    </div>
  </div>
  )
}

export default Documents