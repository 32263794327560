import React from 'react'
import './Main.css'
import './CommunityEngagement.css'

// image
import water from '../assets/aa_water.png'

// components
import Carousel from '../components/Carousel'

// data
import images from '../data/alternatives_analy_data.js'

const Alternatives = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>Alternatives Analysis</h1>

        </div>

        <div className = 'p_wrapper_main'>
          <p className = 'p_main p_ce'> 
          The objective of the Alternatives Evaluation is to identify the preferred long-term approach to upgrade the Water Treatment Plant (WTP) to meet water demands reliably into the future.
          </p>
          <br></br>
          <p className = 'p_main p_ce'> 
          The City of Ann Arbor obtains its drinking water from the Huron River and groundwater wells. Before the water is suitable for drinking, it must be treated: we refer to the water before treatment as the “raw water.” Treatment is necessary to remove pathogens responsible for waterborne diseases, remove other contaminants, and produce water with a pleasing taste, color, and smell.
          </p>
          <br></br>
          <p className = 'p_main p_ce'> 
          City employees will assess the water treatment alternatives based on social, environmental, and financial impacts, and determine the alternative that ensures long-term sustainability of Ann Arbor's award-winning water supply with the least financial and environmental impact.
          </p>
          <br></br>
          <p className = 'p_main p_ce'>
          The Michigan Department of Environment, Great Lakes and Energy (EGLE) will participate in the project through regularly scheduled meetings, data sharing and interim approvals of various steps.
          </p>
        </div>
      </div>

      <div className="right_col_main">
        <Carousel images={images} />
      </div>
      
    </div>
  )
}

export default Alternatives