const images = [{
    id:1,
    src: 'img1.webp',
    alt:'water treatment building, brick and concrete'
},
{
    id: 2,
    src: 'img2.webp', 
    alt: 'interior of treatment plant, large metal pipes'
},
{
    id: 3,
    src: 'img3.webp',
    alt: 'shallow river water with mossy rocks'
},
]

export default images