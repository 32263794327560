import React from 'react'
import './Main.css'

// image
import water from '../assets/aa_water.png'

// components
import Carousel from '../components/Carousel'

// data
import images from '../data/main_data.js'


const Main = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>ann arbor water treatment plant</h1>
          <h1 className = 'title_main'>facility plan</h1>

        </div>

        <div className = 'p_wrapper_main'>
          <p className = 'p_main'> 
            The City of Ann Arbor is beginning a project to create a plan for rehabilitating the City's Water Treatment Plant (WTP). To ensure reliable high-quality water into the future, the City must invest insignificant infrastructure investments. The purpose of the Water Treatment Plant Facility Plan is to evaluate the operational, maintenance and regulatory needs of the plant both now and into the future. The project includes strategic planning, an alternatives analysis, a small pilot plant used to evaluate new technology, and community engagement throughout.
          </p>
          <br></br>
          <p className = 'p_main'>
          Ann Arbor is committed to sustainable, and affordable improvements to water quality and to thorough and in-depth public engagement throughout the planning process. We are interested in your input, thoughts, and concerns and will be sharing various information on this website as the project proceeds.
          </p>
        </div>
      </div>

      <div className="right_col_main">
        <Carousel images={images} />
      </div>
      
    </div>
  )
}

export default Main