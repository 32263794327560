import React from 'react'
import './Main.css'
import './CommunityEngagement.css'

import water from '../assets/aa_water.png'

// components
import Carousel from '../components/Carousel'
import ScheduleComponent from '../components/ScheduleComponent'

// data
import images from '../data/ce_data.js'

const CommunityEngagement = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main left_col_ce'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>Community Engagement</h1>

        </div>

        <div className = 'p_wrapper_main p_wrapper_ce'>
          <p className = 'p_main p_ce opening_paragraph'>Input from the public was sought from Sept. 2022 through Dec. 2023 and feedback from the public and stakeholders  informed the development of the priorities of the Ann Arbor Water Strategic Plan. While we are busy advancing the project concepts and running the one-year pilot demonstration study, you might not hear a lot from us. We plan to return to the public with an update closer to the completion of the Water Treatment Plant Facility Project in June 2025.</p>
          <br></br>
          <p className = 'p_main p_ce'> 
          The City is committed to meaningful and accessible community engagement throughout this project, so read on to find out more about plans for community engagement. The City has contracted with the <a href = 'https://www.huronrivergroup.com/'>Huron River Group</a> (HRG), a local woman-owned firm, to conduct community engagement for this project. With the help of the Water Treatment Plant (WTP) and the AECOM project team, HRG has developed a detailed plan for engagement known as the Community Engagement Action Plan (CEAP). The CEAP is designed to plan comprehensive engagement efforts throughout the project, including identifying and connecting with all important stakeholders. The goal is to incorporate community voices and priorities into the final project recommendations to City Council. Community engagement activities include:
          </p>
          <ol className="p_main p_ce ce_list">
            
              <li>Community events, listening sessions, surveys, and targeted outreach to major stakeholders and historically underrepresented people</li>
              <li>Public information provided through the project website, news articles, and social media</li>
              <li>Consistent, community-informed, analytical feedback from HRG to the project team on all project activities and deliverables, culminating in a comprehensive community engagement report at project close</li>
          </ol>
          <h3 className="ce_section_title p_main p_ce">Equitable Engagement</h3>
          <p className = 'p_main p_ce'> 
            The City is committed to engaging diverse voices through an approachable and welcoming process. We are inviting stakeholders to join in actively as the project team develops its recommendations. Members of the City's <a href = 'https://www.a2gov.org/services/Pages/Equitable-Engagement-Initiative.aspx'>Equitable Engagement Steering Committee (EESC)</a> have joined the WTP Strategic Planning Steering Committee to help inform community perspectives and interests. They will serve as a connection to local organizations and community centers, such as Food Gatherers and the Community Action Network, and to key leaders from historically underrepresented communities. HRG will continue to work with the EESC and community groups to create engagement sessions with community members that include tours to the existing water treatment plant. Our priority is to engage in ways that are comfortable and convenient for community members.
          </p>
          <h3 className="ce_section_title p_main p_ce">Provide Information</h3>
          <p className = 'p_main p_ce'> 
          Public information for this project will illustrate what is happening with drinking water treatment and why, and how these changes may affect people in the future. The City is providing information not only to inform the public about the project, but also to deepen community members' understanding of Ann Arbor's drinking water system. The City believes that water treatment affects everyone, and everyone has a right to know about the future of their drinking water. The CEAP includes a monthly project update, short videos, updates to this website, and more. General information and news from the Water Treatment Plant can be found in the <a href = 'https://www.a2gov.org/departments/water-treatment/Pages/Quality-Water-Matters-Archive.aspx'>Quality Water Matters Archive.</a>
          </p>
          <h3 className="ce_section_title p_main p_ce">Answer Questions from Community</h3>
          <p className="p_main p_ce">
            The City not only wants to raise questions through public information, but give resources to answer these questions. The Water Treatment Plant planning team attended the Mayor's Green Fair, the Ann Arbor Farmer's Market in October, and two food distribution lines at the Bryan Community Center in November. We plan to attend additional events throughout the life of the project to answer public questions and hear feedback. HRG have conducted focus groups with highly interested groups, such as home brewers and medical providers, with more to come. A town hall event is included later in the scope of this project, to give people a chance to learn and ask the project team questions. Keep your eye on the <a href = '/'>project website</a> for information about other engagement efforts as they come up. These events will also be promoted through City newsletters and social media.
          </p>
          <h3 className="ce_section_title p_main p_ce">Inform Project Decision Makers</h3>
          <p className="p_main p_ce">
          Project activities are continuously monitored to identify policy decisions that should be influenced by public priorities. For example, the project team is using weighted criteria to select the preferred treatment technology. The criteria include environmental, technical, and social factors, weighed against fiscal impacts to create a bottom-line score. The <a href = 'https://www.opentownhall.com/portals/116/Issue_12247'>survey</a> asked the public to prioritize these environmental, technical, social, and cost factors, to weight the criteria and inform the recommended technology. The project team is continually looking for further opportunities for the public to influence project decisions.
          </p>
          <p className="p_main p_ce p_ce_bottomparagraph">
          Water rates in Ann Arbor were increased in 2015 to support the cost of this improvement project; whether additional rate increases might be recommended is still to be determined based on the team's analysis. The City believes it is important to speak to community organizations that research and advocate for low-income and historically underrepresented groups, instead of solely attempting to connect to these community members themselves. Organizations are able to frame issues with a broader, system-wide lens, leading to policy recommendations that address environmental, equity, access, and other issues. To this end, we have met with representatives of the Huron River Watershed Council, the University of Michigan Water Equity Study, major customers, local hospitals and health care providers, the National Sanitation Foundation, and more.
          </p>

          <div className='ce_bottom'>
            <ScheduleComponent />
            <div className="right_col_ce">
              <Carousel images={images} /> 
            </div>
          </div>
          
        </div> {/* main wrapper closing tag */}
      </div>


  </div>
  )
}

export default CommunityEngagement