import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';

// components
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'

// main pages
import Main from './pages/Main.jsx'
import CommunityEngagement from './pages/CommunityEngagement'
import StrategicPlanning from './pages/StrategicPlanning'
import Alternatives from './pages/Alternatives'
import PilotPlant from './pages/PilotPlant'
import Implementation from './pages/Implementation'

// side pages
import Contact from './pages/Contact'
import Schedule from './pages/Schedule'
import Documents from './pages/Documents'
import Faqs from './pages/Faqs'

import './App.css'

// analytics
ReactGA.initialize("G-4BCGE351TB");

function App() {


  return (
    <div className="App">
      <BrowserRouter>
      
        <Navbar />
        <Sidebar />
          <main>
          
            <Routes>
              <Route path='/' element={<Main />} />
              <Route path='/community-engagement' element={<CommunityEngagement />} />
              <Route path='/strategic-planning' element={<StrategicPlanning />} />
              <Route path='/alternatives-analysis' element={<Alternatives />} />
              <Route path='/pilot-plant' element={<PilotPlant />} />
              <Route path='/implementation' element={<Implementation />} />
              <Route path='/contact-us' element={<Contact />} />
              <Route path='/schedule' element={<Schedule />} />
              <Route path='/documents' element={<Documents />} />
              <Route path='/faqs' element={<Faqs />} />
            </Routes>
          </main>
          <footer>
          <Footer />
          </footer>
        
      </BrowserRouter>
    </div>
  )
}

export default App
