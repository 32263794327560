import React from 'react'

const ScheduleComponent = () => {
  return (
    <div className='schedule__wrapper'>
        <section className='schedule__upcoming'>Upcoming Events</section>
        <ul className='schedule__event__list'>
        <li>
              <span className='schedule_event_title'>Nothing currently, check back often for updates!</span>
              <span className='schedule_event_info'></span>
            </li>
        </ul>

        <section className="schedule__past">Past Events</section>
        <ul className="schedule__event__list">

            <li>
              <span className='schedule_event_title'><a href = 'https://www.a2gov.org/departments/water-treatment/Pages/Drinking-Water-Week.aspx'>Water Treatment Plant Open House</a></span>
              <span className='schedule_event_info'>Come celebrate national drinking water week at the water treatment plant! On May 6th, 2023, at the Water Treatment Plant (919 Sunset Road), staff will be conducting tours of the Plant, with additional information and activities about water.</span>
            </li>
          
            <li>
              <span className='schedule_event_title'><a href = 'https://discoverscienceandnature.org/a2earthday'>Earth Day Festival 2023</a></span>
              <span className='schedule_event_info'>Come to Leslie Science Center on April 23rd between 12 and 4 PM. The project team will be there with games, free giveaways, and a water tasting event!</span>
            </li>
            
            <li>
              <span className='schedule_event_title'> <a href = 'https://powwow.umich.edu'>Dance For Mother Earth Powwow</a></span>
              <span className='schedule_event_info'>Visit with the project team on March 25th at Skyline High School to get your questions answered about Ann Arbor Water! You can also enjoy an opportunity to experience and honor Native American culture.</span>
            </li>

            <li>
              <span className='schedule_event_title'>Ann Arbor Farmer's Market</span>
              <span className='schedule_event_date'>Wednesday, Oct. 12 | 8:30-2 p.m. Downtown Ann Arbor</span>
              <span className='schedule_event_loc'>315 Detroit Street</span>
              <span className='schedule_event_info'>Visit the Ann Arbor Water table to get answers to your water questions from City staff during the Ann Arbor Farmer's Market located on downtown at 315 Detroit Street.</span>
              <span className="schedule_event_link"><a href='https://www.a2gov.org/departments/Parks-Recreation/parks-places/farmers-market/pages/default.aspx'>Ann Arbor Farmer's Market</a></span>
            </li>

            <li>
              <span className='schedule_event_title'>Second Annual Mayor's Autumnal Green Fair</span>
              <span className='schedule_event_date'>Friday, Oct. 7 | 6-9 p.m. Downtown Ann Arbor</span>
              <span className='schedule_event_loc'>Main Street and Library Lot/Lane</span>
              <span className='schedule_event_info'>Visit city staff to get answers to your water questions during the Mayor's Autumnal Green Fair located on downtown Main Street andthe Library Lot and Lane. The Green Fair also will feature a multitude of exhibits showcasing local green businesses and non-profits, alongwith A2ZERO collaborators.</span>

              <span className="schedule_event_link"><a href='https://www.a2gov.org/departments/sustainability/Newsletter-Events/Pages/Green-Fair.aspx'>Green Fair</a></span>
            </li>



            
        </ul>
    </div>
  )
}

export default ScheduleComponent