// this page is not currently visible in live version


import React from 'react'
import './Main.css'

import water from '../assets/aa_water.png'

const Contact = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
        
        <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
        <div className = 'title_wrapper_main'>

          <h1 className = 'title_main'>Contact Us</h1>
        </div>

        <div className = 'p_wrapper_main'>
          <p className = 'p_main p_sp'> 
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec adipiscing tristique risus nec feugiat in fermentum. Sem integer vitae justo eget. Scelerisque in dictum non consectetur a. Elit duis tristique sollicitudin nibh sit amet. Vel turpis nunc eget lorem dolor. Egestas purus viverra accumsan in nisl nisi scelerisque eu ultrices. Ullamcorper morbi tincidunt ornare massa eget egestas. Viverra accumsan in nisl nisi scelerisque. Risus in hendrerit gravida rutrum quisque non tellus. Quisque egestas diam in arcu cursus euismod quis. Aliquam sem et tortor consequat id. Nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus.
          </p>
          <br></br>
          <p className = 'p_main p_sp'>
          Nulla at volutpat diam ut. Vulputate mi sit amet mauris commodo quis. Libero volutpat sed cras ornare arcu dui vivamus arcu. Suspendisse ultrices gravida dictum fusce. Sit amet aliquam id diam. Elit pellentesque habitant morbi tristique senectus et netus et. Feugiat scelerisque varius morbi enim nunc faucibus. Iaculis eu non diam phasellus vestibulum lorem sed risus ultricies. Eget est lorem ipsum dolor sit amet consectetur adipiscing. Vestibulum morbi blandit cursus risus at ultrices mi tempus imperdiet. Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Sed augue lacus viverra vitae congue eu consequat ac. Tellus rutrum tellus pellentesque eu tincidunt tortor. Rhoncus dolor purus non enim praesent elementum. In massa tempor nec feugiat. Cras pulvinar mattis nunc sed. Dui faucibus in ornare quam viverra orci sagittis eu volutpat. Fames ac turpis egestas maecenas pharetra convallis. Orci a scelerisque purus semper.
          </p>
        </div>
      </div>

    </div>
  )
}

export default Contact