import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'

const Carousel = ({images}) => {

  const settings = {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3500,
};

  return (
    <>
      <div className="imgslider">
        <Slider {...settings}>
          {images.map((item) => (
            <div key={item.id} className='slider_interior'>
              <img src={`/img/${item.src}`}  alt={item.alt} />
            </div>
          ))}
        </Slider>  
      </div>
    </>
  )
}

export default Carousel