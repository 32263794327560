import React, { useState } from 'react'
import './Navbar.css'
import { NavLink, Link} from 'react-router-dom';

import logo from '../assets/logo_w.png'



const Navbar = () => {

  let activeClassName = "activated"
  let nonActiveClassName = 'nonactivated'
  const [showMenu, setShowMenu] = useState(false);

  const clickHandler = () => setShowMenu(!showMenu)

  return (
    <>
        <nav className="rc_nav" id="centered_nav">
          <NavLink to ='/' className={({isActive}) => 
              isActive ? activeClassName : nonActiveClassName
            }
          >
            <p title = "Home" className='nav_link'>Home</p>
          </NavLink>

          <NavLink to = '/community-engagement' className={({isActive}) => 
              isActive ? activeClassName : nonActiveClassName
            }
          >
            <p title= "Community Engagement" className='nav_link' >Community Engagement</p>
          </NavLink>

          <NavLink to = '/strategic-planning' className={({isActive}) => 
              isActive ? activeClassName : nonActiveClassName
            }
          >
            <p title= "Strategic Planning" className='nav_link'>Strategic Planning</p>
          </NavLink>

          <NavLink to = '/alternatives-analysis' className={({isActive}) => 
              isActive ? activeClassName : nonActiveClassName
            }
          >
            <p title= "Alternatives Analysis" className='nav_link'>Alternatives Analysis</p>
          </NavLink>

          <NavLink to = '/pilot-plant' className={({isActive}) => 
              isActive ? activeClassName : nonActiveClassName
            }
          >
            <p title= "Pilot Plant" className='nav_link'>Pilot Plant</p>
          </NavLink>

          <NavLink to = '/implementation' className={({isActive}) => 
              isActive ? activeClassName : nonActiveClassName
            }
          >
            <p title= "Implementation" className='nav_link'>Implementation</p>
          </NavLink>
        
        </nav>

      <div className="mobile_nav">
        <div className = 'mobile_header'>
          <div className="mobile_site_name">
            <h1>Water</h1>
            <h1>Treatment</h1>
            <h1>Plan</h1>
          </div>

          <button id = 'hamburger__button' onClick = {clickHandler}>
            <svg id='hamburger'  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>

        {showMenu ?         
        <div className="nav_menu_wrapper">
          <ul className = 'mobile_menu'>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/'> Home </Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/community-engagement'> Community Engagement</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/strategic-planning'>Strategic Planning</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/alternatives-analysis'>Alternatives Analysis</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/pilot-plant'>Pilot Plant</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/implementation'>Implementation</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/schedule'>Schedule</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/documents'>Documents</Link></li>
              <li className='mobile_menu_item' onClick={clickHandler}><Link to ='/faqs'>FAQs</Link></li>
            </ul>
        </div>
        : null}
        {/* UL here for menu items. Conditional render for show when showMenu state == true */}
      </div>


      <div id="logo">
        <a href = 'https://www.a2gov.org/departments/water-treatment/Pages/default.aspx'><img src={logo} alt="ann arbor seal" className='site_logo'/></a>
      </div>

    </>
  )
}

export default Navbar