import React from 'react';
import { Link } from 'react-router-dom'
import './Sidebar.css'

// removed for now: <span><Link to='/contact-us'>Contact Us</Link></span>

const Sidebar = () => {
  return (
    <nav className = 'sidebar__nav'>
        <div className="sidebar__list">
            
            <span><Link to ='/schedule'>Schedule</Link></span>
            <span><Link to ='/documents'>Documents</Link></span>
            <span><Link to ='/faqs'>FAQs</Link></span>  
        </div>
    </nav>
  )
}

export default Sidebar