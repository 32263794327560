import React from 'react'
import './Main.css'
import './CommunityEngagement.css'


// image
import water from '../assets/aa_water.png'

// components
import Carousel from '../components/Carousel'

// data
import images from '../data/pilot_data.js'

const PilotPlant = () => {
  return (
    <div className = 'wrapper_main'>
      <div className = 'left_col_main'>
      
      <img src={water} alt="ann arbor water" className = 'water_logo_main'/>
      <div className = 'title_wrapper_main'>

        <h1 className = 'title_main'>Pilot Plant Demonstration Testing</h1>

      </div>

      <div className = 'p_wrapper_main'>
        <p className = 'p_main p_ce'> 
        Pilot testing will provide real-world information on how treatment alternatives work with the City's water sources prior to full-scale implementation. The pilot plant will implement the recommended process on a small scale. Pilot testing uses small-scale treatment equipment that mimics proposed improvements combined with other existing treatment processes. Pilot testing will compare the results of the single-stage softening process against the existing multi-step softening processes. Water quality testing in the pilot plant includes continuous online analyzers and laboratory results for water quality parameters.  Water quality is monitored in both the raw water and treated water throughout the processes.
        </p>
        <br></br>
        <p className = 'p_main p_ce'> 
        The pilot plant will be installed at the site of the Water Treatment Plant. Site preparation and construction of the building will begin in February 2023 and the pilot plant will be in operation throughout 2023 and into 2024. The first pieces of equipment will be arriving in late summer/early fall 2022 with construction wrapping up late spring/early summer 2023.
        </p>
        <br></br>
        <p className = 'p_main p_ce'> 
        Due to the size requirements to represent real world performance, a two-story enclosure must be constructed to house the pilot plant equipment. The enclosure will be within the fenceline of the existing water treatment site on Sunset Road. Some general construction noise is anticipated during the upcoming winter months as the enclosure is constructed. The equipment does not have excessive noise during operations.
        </p>
      </div>
    </div>

    <div className="right_col_main">
      <Carousel images={images} />
    </div>
    
  </div>
  )
}

export default PilotPlant